



































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { ValidationObserver } from 'vee-validate';
@Component
export default class Reset extends Vue {
    @Ref() observer!: InstanceType<typeof ValidationObserver>;
    step = 1;
    form = {
        password: '',
        confirmation: ''
    };

    async onSubmit() {
        try {
            await agentModule.resetPassword({
                password: this.form.password,
                token: this.$route.params.token
            });
            this.step++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
